<!--
 * @Descripttion:标准基础查询
 * @version:1.0.0
 * @Author: huchongyuan
 * @Date: 2021-03-11 14:16:16
 * @LastEditors: huchongyuan
 * @LastEditTime: 2021-04-21 22:18:48
-->
<template>
   <div class="normBaseQuery">
      <div class="normBaseQueryHeader">
         <Form ref="formInline" label-position="right" :label-width="80" inline>
            <QueryParam ref="QueryParam" />
            <FormItem label="标准类别" style="margin-left:80px;">
               <Input type="text" v-model="standCate" placeholder="请输入标准类别" />
            </FormItem>
            <FormItem class="queryBtn">
               <Button type="primary" class="leftBtn" @click="query">查询</Button>
               <Button class="leftBtn" @click="reset">重置</Button>
               <!-- <Button type="primary" @click="toUpperQuery">高级查询</Button> -->
            </FormItem>
         </Form>
      </div>
      <div class="normBaseQueryContent">  
         <QueryResult ref="QueryResult" :columns="columns" />
      </div>
      <statisticsModal ref="statisticsModal" />
      <PdfModal ref="PdfModal" />
      <ModifyModal ref="ModifyModal" />
   </div>
</template>
<script>
import QueryResult from '@/components/QueryResult';
import QueryParam from '@/components/QueryParam';
import NormBaseQuery from '@/api/NormBaseQuery';
import statisticsModal from '@/components/statisticsModal';
import PdfModal from '@/components/PdfModal';
import ModifyModal from '@/components/ModifyModal';
export default {
   name:"NormBaseQuery",
   data(){
      return {
         standCate:"",
         columns:[
            {
               "title":"序号",
               "key":"indexNo",
               "width":65,
               "fixed":'left'
            },
            {"title":"标准号","key":"standNo","fixed":'left',
               "width":150,
               "render":(h, params) => {
                  var value = params["row"]["standNo"];
                  let name =  params["row"]["standName"];
                  return h('Tooltip',{props:{placement: 'top'}},[
                     h('a', {
                           on: {
                              click: () => {
                                 this.$refs["statisticsModal"].open({
                                    "standNo":value,
                                    "modalName":value+"　　　"+name
                                 });
                              }
                           }
                        }, value),
                      h('span', {slot: 'content', style: {whiteSpace: 'normal', wordBreak: 'break-all'}}, "点击查询标准详细数据")
                     ]);
               }
            },
            {"title":"标准名称","key":"standName","fixed":'left',
               "width":300,
               "render":(h, params) => {
                  let value = params["row"]["standName"];
                  let fjUrl = params["row"]["fjUrl"];
                  return h('Tooltip',{props:{placement: 'top'}},[
                     h('a', {
                           on: {
                              click: () => {
                                 this.$refs["PdfModal"].open(fjUrl);
                              }
                           }
                        }, value),
                     h('span', {slot: 'content', style: {whiteSpace: 'normal', wordBreak: 'break-all'}}, "点击查询标准原文")
                     ]);
               }
            },
            {"title":"英文名称","key":"engName","width":250},
            {"title":"发布日期","key":"pubDate","width":150,
               "render":(h, params) => {
                  let {pubDate} = params['row'];
                  let newPubDate = this.timestampToTime(pubDate);
                  return h('div', [h('span', {},newPubDate)]);
               }
            },
            {"title":"实施日期","key":"estDate","width":150,
               "render":(h, params) => {
                     let {estDate} = params['row'];
                     let newPubDate = this.timestampToTime(estDate);
                     return h('div', [h('span', {},newPubDate)]);
                  }
            },
            {"title":"标准ICS号","key":"ics","width":150},
            {"title":"中标分类号","key":"ccs","width":150},
            {"title":"替代以下标准","key":"subtitute","width":150},
            {"title":"标准分类","key":"standClass","width":150,"render":(h, params) => { let value = this.formatterVal(params,'standClass');return h('div',[h('span', {}, value)]);}},
            {"title":"标准类别","key":"standCate","width":150},
            {"title":"主管部门","key":"chargeDep","width":220},
            {"title":"归口单位","key":"resposibleDep","width":220},
            {"title":"提出修订意见",
               "fixed":'right',
               "width":130,
               "render":(h, params) => {
                  let {table,tableId,standNo,standName} = params['row'];
                  return h('div', [
                     h('a', {
                           on: {
                              click: () => {
                                  this.$refs["ModifyModal"].open({
                                     "table":table,
                                     "tableId":tableId,
                                     "standNo":standNo,
                                     "standName":standName,
                                     "otherInfo":standName
                                  });
                              }
                           }
                        }, '提出修订意见')
                     ]);
               }
            }
         ]
      }
   },
   components:{
      "QueryResult":QueryResult,
      "QueryParam":QueryParam,
      "statisticsModal":statisticsModal,
      "PdfModal":PdfModal,
      "ModifyModal":ModifyModal
   },
   mounted(){
     
   },
   activated(){
      // 获取查询参数;
      if(this.$route.params.standName){
         let {standName} = this.$route.params;
         this.$refs["QueryParam"].setParam({standName})
      }
      if(this.$route.params.standNo){
        let {standNo} = this.$route.params;
        this.$refs["QueryParam"].setParam({standNo})
      }
      if(this.$route.params.query && this.$route.params.query == "1"){
         this.query();
      }else{
         //  this.reset();
         //  this.$refs["QueryResult"].query(NormBaseQuery.query,{
         //     "standName":"",
         //     "standNo":"aaa",
         //     "standCate":""
         //  },true);
      }
   },
   methods:{
      query(){
         let queryParam = this.$refs["QueryParam"].getParam();
         let standCate = this.standCate;
          if(queryParam["standNo"]=="" && queryParam["standName"]=="" && this.standCate == ""){
             this.$Message.error('请输入关键字进行查询');
          }else{
             let standCate = this.standCate;
            this.$refs["QueryResult"].query(NormBaseQuery.query,{...queryParam,standCate},true);
          }
      },
      // 跳转到高级查询;
      toUpperQuery(){
         this.$router.push({"name":"NormAdvancedQuery"});
      },
      formatterVal(params,key){
         let result = JSON.parse(sessionStorage.getItem("DD003"));
         let row = params['row'];
         return result.filter((item)=>{return item["statusNo"] == row[key]})[0]["statusDesc"];
      },
      timestampToTime(timestamp) {
        var date = new Date(timestamp);
        var Y = date.getFullYear()+'';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1)+'';
        var D = (date.getDate()<10?('0'+date.getDate()):date.getDate())+'';//' ';
        var h = date.getHours() + ':';
        var m = date.getMinutes() + ':';
        var s = date.getSeconds();
        return Y+"-"+M+"-"+D;
      },
      renderDate(h, params){
         let {table,tableId,standNo,standName} = params['row'];
            return h('div',[h('span',{},'提出修订意见')]);
      },
      reset(){
        this.$refs["QueryParam"].reset();
        this.$set(this,'standCate','');
      }
   }
}
</script>
<style lang="less">
.normBaseQueryHeader{
   height:58px;
   .ivu-form-item{
      float:left;
   }
   .leftBtn{
      margin-right:20px;
   }
   .queryBtn{
      .ivu-form-item-content{
            margin-left:20px !important;
      }
   }
}

</style>
